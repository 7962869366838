import React from 'react';
import BaseTemplate from '../../../components/templates/BaseLayout';
import SEO from '../../../components/atoms/utility/seo';
import Spacer from '../../../components/atoms/utility/Spacer';
import EventsListBlogV2 from '../../../components/organisms/EventsListBlogV2';
import { austinEvents } from '../../../content/events/austin-events';

function AustinDaytime() {
  return (
    <BaseTemplate>
      <SEO
        title={'Daytime Activities Austin'}
        description={'Come find events to do during the day in Austin'}
      />
      <Spacer sizeY={1} className="" />
      <EventsListBlogV2
        cityName="Austin"
        categoryPage={'Daytime'}
        eventsData={austinEvents}
      />
      <Spacer sizeY={1} className="" />
    </BaseTemplate>
  );
}

export default AustinDaytime;
